import {Alert, Form, Input, Modal} from 'antd';
import {t}                         from 'foundations/i18n/i18n';
import {useNavigate}               from 'react-router';
import {useApi}                    from 'foundations/api/use-api';
import {useForm}                   from 'foundations/form/use-form';

export function CreateProviderModal({
    open,
    toggle
}: {
    open: boolean,
    toggle: () => void
}) {
    const form     = useForm({
        name:             '',
    });
    const navigate = useNavigate();
    const api      = useApi('post', '/providers/create', form.data, {
        onSuccess: (res) => navigate(`/providers/${res.data[0].id}`)
    });

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Provider')}
            onOk={api.submit}
            okButtonProps={{children: t('Create'), onClick: api.submit, loading: api.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Name')}>
                    <Input {...form.register('name')}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}