import React           from 'react';
import ReactDOM        from 'react-dom/client';
import {App}           from 'app';
import './index.css';
import {Auth0Provider} from '@auth0/auth0-react';
import {Tenancy}       from '@boomhealth/tenancy';

const tenantSubdomain = window.location.host.startsWith('localhost') ? 'local' : window.location.host.split('.')[0];
Tenancy.init(tenantSubdomain);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={Tenancy.current.AUTH0_DOMAIN}
            clientId={Tenancy.current.AUTH0_CLIENT_IDS.BACKHOUSE}
            authorizationParams={{
                audience:     'core-api',
                redirect_uri: window.location.origin
            }}
        >
            <App/>
        </Auth0Provider>
    </React.StrictMode>
);
